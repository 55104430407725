import React, { useState } from 'react';
import FusionRender from "./FusionRender";
import { Helmet } from "react-helmet";
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"
import {
    selectCart, CartItem, removeFromCart, emptyCart, SetCartItemQuantityParam, setCartItemQuantity
} from './searchSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const configDataOfAnyType: any = JSON.parse(JSON.stringify(configData));

export function Cart() {
    const [checkOut, setCheckOut] = useState(false);

    const titlePrefix = configDataOfAnyType[getCurrentEnvironment()].page_title_prefix;
    const cart = useAppSelector(selectCart);
    const count = cart.reduce((acc, item) => acc + item.quantity, 0);
    const dispatch = useAppDispatch();

    const handleCartItemDelete = (productId: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        console.log("cart item delete clicked for product: " + productId);
        dispatch(removeFromCart(productId));
    }

    const handleCheckout = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        //sleep for 2 seconds to simulate a checkout process
        setTimeout(() => {
             dispatch(emptyCart());
             setCheckOut(true);
         }, 2000);
        //dispatch(emptyCart());
        //setCheckOut(true);
    }

    const handdleQuantityChange = (productId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const param: SetCartItemQuantityParam = {
            id: productId,
            quantity: parseInt(e.target.value)
        }
        dispatch(setCartItemQuantity(param));
    }

    const randomOrderNumber = Math.floor(Math.random() * 1000000000);
    
    //format with 2 decimal places
    const cartTotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

    return (
        <React.Fragment>
            <section className="shipping_area mb-50">
                <div className="container">
                </div>
            </section>


            <div className="shopping_cart_area mt-32">

                <div className="container">
                    
                    {(checkOut)
                    ? <>
                    
                    <div className="checkout_head">
           <h3> Order Confirmation</h3>
        </div>
        <div className="checkout_content">
            Thank you for your order!<br/>
            Your order has been received and is currently being processed.<br/>
            Your order number is: {randomOrderNumber}<br/>
            We will notify you once your order is on its way.
        </div>
        <div className="checkout_footer">
            If you have any questions, please contact our customer support.
        </div>
                    
                     </>
                    : <>
                    <h2 className="py-3">Your Shopping Cart</h2>
                    {(count > 0)
                        ? <form action="#">
                        <div className="row">
                            <div className="col-12">
                                <div className="table_desc">
                                    <div className="cart_page table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="product_remove">Delete</th>
                                                    <th className="product_thumb">Image</th>
                                                    <th className="product_name">Product</th>
                                                    <th className="product-price">Price</th>
                                                    <th className="product_quantity">Quantity</th>
                                                    <th className="product_total">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.map((item) => (
                                                    <tr key={item.id} data-product-id={item.id}>
                                                        <td className="product_remove"><a href="#" onClick={handleCartItemDelete(item.id)}><i className="fa fa-trash-o"></i></a></td>
                                                        <td className="product_thumb"><a href="#"><img src={item.thumbnail} alt="" /></a></td>
                                                        <td className="product_name"><a href="#">{item.title}</a></td>
                                                        <td className="product-price">${item.price}</td>
                                                        <td className="product_quantity"><label>Quantity</label> <input min="1" max="100" defaultValue={item.quantity} type="number" step={1} onChange={handdleQuantityChange(item.id)} /></td>
                                                        <td className="product_total">${item.price * item.quantity}</td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="coupon_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="coupon_code left">
                                        <h3>Coupon</h3>
                                        <div className="coupon_inner">
                                            <p>Enter your coupon code if you have one.</p>
                                            <input placeholder="Coupon code" type="text" />
                                            <button type="submit">Apply coupon</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="coupon_code right">
                                        <h3>Cart Totals</h3>
                                        <div className="coupon_inner">
                                            <div className="cart_subtotal">
                                                <p>Subtotal</p>
                                                <p className="cart_amount">${cartTotal}</p>
                                            </div>
                                            <div className="cart_subtotal ">
                                                <p>Shipping</p>
                                                <p className="cart_amount"><span>Free:</span> $0.00</p>
                                            </div>

                                            <div className="cart_subtotal">
                                                <p>Total</p>
                                                <p className="cart_amount">${cartTotal}</p>
                                            </div>
                                            <div className="checkout_btn">
                                                <button onClick={handleCheckout} disabled={checkOut}>Complete Checkout</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                        : <h3>Your cart is empty</h3>
                    }
                    
                    </>
                    }
                    
                </div>
            </div>

            {/*Sets the Page Title in the <head> section of the Page*/}
            <Helmet>
                <title>{titlePrefix}</title>
            </Helmet>
        </React.Fragment>
    )
}
export default Cart